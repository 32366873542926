import "scroll-behavior-polyfill";
import "react-aspect-ratio/aspect-ratio.css";
import '@djthoms/pretty-checkbox';
import '@splidejs/react-splide/css';
import "react-modern-drawer/dist/index.css";

// BUG BROWSER ANTIGOS
if(typeof window !== 'undefined') 
{
    Object.defineProperty(Array.prototype, 'at', {
        enumerable: false,
        writable: true
    });
}

import { Footer } from "../Footer"; 
import { Header } from "../Header";
import { DefaultSeo } from 'next-seo';
import { useRouter } from "next/router";
import { Toaster } from "react-hot-toast";
import NextNProgress from "nextjs-progressbar";
import CookieConsent from "react-cookie-consent";
import { WhatsAppIcon } from '../../component-nextv3/WhatsAppIcon';
import { THEME_SETTING } from '../../setting/setting';
import ErrorBoundary from "../../component-nextv3/ErrorBoundary";
import { useEffect } from "react";
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { pageViewsAnalytics } from "../../core-nextv3/analytics/analytics.api";
import { useCore } from "../../core-nextv3/core/core";
import { disableReactDevTools } from "../../core-nextv3/util/disableReactDevTools";
import { useScrollRestoration } from "../../core-nextv3/util/useScrollRestoration";
import { PopupModal } from "../../component-nextv3/PopupModal";
import { IconsLink } from "../IconsLink";

const AppPage = ({ Component, pageProps }: any) => 
{
    const router   = useRouter();
    const { user } = useCore();

    useScrollRestoration();	

    useEffect(() => {
      if (router.isReady) {
        if (pageProps.account && pageProps.account.gtm) { 
          tagManager4.initialize(pageProps.account.gtm);
          tagManager4.pageView(
            router.asPath,
            pageProps.seo?.title,
            "b2b",
            "ecommerce",
            window.orientation > -1 ? "mobile" : "desktop",
            "Pippa",
            user
          );
        }

        pageViewsAnalytics(pageProps.seo);
      }
  }, [router.isReady, router.asPath]);

  useEffect(() => 
  {
      disableReactDevTools();      
  }, []);

      useEffect(() => {
				const script = document.createElement('script');
				script.src = 'https://www.google.com/recaptcha/enterprise.js?render=' + process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
				script.async = true;
				document.head.appendChild(script);
		}, []);

    return (
        <>
            <DefaultSeo {...pageProps.seo}/>           
            <NextNProgress
              color="var(--theme-color);"
              startPosition={0.3}
              stopDelayMs={200}
              height={4}
              showOnShallow={true}
              options={{ easing: "ease", speed: 500 }}
            />
            <ErrorBoundary router={router}>
              {!pageProps.disabledHeader && <Header
                menu={pageProps.menu}
                colorTable={pageProps.colorTable}
                topHeader={pageProps.topHeader}
                gateway={pageProps.gateway}
                cartPage={pageProps.cartPage}
              />}
              {pageProps.popup && pageProps?.popup?.published && <PopupModal popup={pageProps.popup} />}
              <Component {...pageProps} />
              {!pageProps.disabledFooter && <Footer
                account={pageProps.account}
              />}
            </ErrorBoundary>
            <Toaster
              position="top-center"
              reverseOrder={true}
              containerStyle={{
                fontSize: 16,
              }}
              toastOptions={{
                duration: 2500,
              }}
            />
            <IconsLink account={pageProps.account} />
            <CookieConsent
              location="bottom"
              buttonText="Aceitar"
              cookieName="rgoficial"
              style={{ background: "#2B373B", fontSize: "14px", textAlign:"center" }}
              buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
            >
                <div dangerouslySetInnerHTML={{ __html: THEME_SETTING.cookieConsent }}></div>
            </CookieConsent>
        </>
    );
};

export { AppPage };
