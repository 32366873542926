import { setting } from "../core-nextv3/util/setting";

const platform = {
	id    : "Cihz25o1",
	label : "E-commerce",
	value : "ecommerce"
}

const THEME_SETTING : any = {
	// aspectRatio 	  		      : 1,
	maxParcel 	  	  		      : 5,
	minValue 	  	  		      : 40,
	revalidate        		      : 60 * 30,
	widthLogo                     : 100,
	heightLogo                    : 30,
	widthBannerMain               : 1920,
	heightBannerMain              : 680,
	widthProductThumb             : 1000,
	heightProductThumb            : 1000,
	widthCategoryThumb			  : 1000,
	heightCategoryThumb			  : 1000,
	showAll					      : true,
	videosHome       		      : false,
	disabledFilter    	          : false,
	disabledPackaging  		      : true,
	noStock          		      : false,
	onlyLogged         		      : false,
	disabledVariantTypeLabel      : false,
	disabledMeasurementTableModal : false,
	disabledIconLinkThree         : true,
	disabledLogoUpCommerce        : true,
	variantLayout      		      : 'button',
	productPath 				  : '/produtos/',
	registrationLoginTypeForm     : 'CPF',
	footerInfo 				      : '<p>© 2022 Todos os direitos reservados à TENTAZIONE IMPORTAÇÃO E EXPORTAÇÃO DE ACESSÓRIOS LTDA</p><p>CNPJ: 09.371.492/0001-04 </p>',
	sacInfo1  				      : '<p>Atendimento sobre ao seu pedido ou devolução</p><p>Email: sac@rgoficial.com.br</p><p>Whatsapp: (11) 99649-7638</p><p>Horário de Atendimento: Segunda à Sexta das 09h00 às 17h00</p>',	
	cookieConsent 	   	 	      : `Olá cliente Ready.Go, utilizamos cookies com fins analíticos e para mostrar anúncios relacionados a suas preferências. Para mais informações visite a nossa <a href="/loja/privacidade-e-seguranca" style='font-size: 14px; color : #fff '>Política de Privacidade</a>`,
	//noteShipping              : `Os pedidos realizados a partir do dia 20/12 serão enviados após o dia 08/01/24`,
};

// TRACK
const TRACK_SETTING = setting({
	accid: "default",
	appid: "shippings",
	colid: "documentsShippings",
	platform: platform,
	shipping : {
		referencePath : 'default/shippings/documentsShippings/yl5I2NBKFTuhGby9i70N'
	},
	mapItems: {
		referencePath: "default/accounts/grids/5vTO1pyeQ1DrUjtmzOTx",
	}
});

// MENU FOOTER
const MENU_FOOTER_STATIC = [{
	label : 'Institucional',
	url   : '/loja/sobre-nos',
	children : [
	{
		label : 'Sobre Nós',
		url   : '/loja/sobre-nos/',
	},
	{
		label : 'Avaliações',
		url   : '/avaliacoes/',
	}]
},
{
	label : 'Dúvidas',
	url   : '/loja/sobre-nos',
	children : [
	{
		label : 'Pagamentos e envio',
		url   : '/loja/pagamentos-e-envio/',
	},
    {
		label : 'Privacidade e Segurança',
		url   : '/loja/privacidade-e-seguranca/',
	},
    {
		label : 'Como Cuidar',
		url   : '/loja/como-cuidar/',
	},
    {
		label : 'Trocas e Devoluções',
		url   : '/loja/trocas-e-devolucoes-garantia/',
	}]
},
{
	label : 'Minha Conta',
	url   : '/loja/sobre-nos',
	children : [
	{
		label : 'Minha Conta',
		url   : '/loja/minha-conta',
	},
    {
		label : 'Meus Pedidos',
		url   : '/loja/meus-pedidos',
	},
    {
		label : 'Meus Favoritos',
		url   : '/loja/meus-favoritos',
	},
    {
		label : 'Cadastrar',
		url   : '/loja/cadastrar',
	}]
}];

// ACCOUNT
const ACCOUNT_SETTING = setting({
	accid : "default",
	appid: "accounts",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/accounts/grids/5vTO1pyeQ1DrUjtmzOTx",
	},
});

//TOPHEADER
const TOP_HEADER_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/niqCUkQDg0FxmYdGhqFJ",
	},
});

// MENU
const MENU_SETTING = setting({
	accid: "default",
	appid: "menuEcom",
	colid: "documentsMenu",
	platform: platform,
	orderBy: "order",
	map: true,
	mapItems: {
	  	referencePath: "default/menuEcom/grids/3sczoK38BE1lGq2mZ08W",
	},
});

// CATEGORY
const CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/6diDjwiaSnSRWAsxwiL8",
	},
});

const SLUG_CATEGORY_SETTING = setting({
	accid: "default",
	appid: "category",
	colid: "documents",
	platform: platform,
	orderBy: "name",
	map: true,
	mapItems: {
	  	referencePath: "default/category/grids/dPZoNcC2LTtxipBEP2WI",
	},
});

//ORDER
const ORDER_SETTING = setting({
	accid: "default",
	appid: "financial",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	ownerField : 'client',
	form: {
        referencePath: "default/financial/forms/yBwJpLgkPRGiJcjVlz4r",
	},
	mapItems: {
		referencePath: "default/financial/grids/Pxn8ESNxcjMqviSVw7KN",
	},
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
	giftPage : {
		referencePath : 'default/page/documents/GDNnMlcjmCuhBbm1MDAo'
	}
});

const GATEWAY_SETTING = setting({
	accid: "default",
	appid: "gateway",
	colid: "documents",
	platform: platform,
	document: {
	  referencePath: "default/gateway/documents/F2JEy0TFZjhd63XOCosX",
	},
	mapItems: {
		referencePath: "default/gateway/grids/2zFOjiFvbmEZoWlc9ZuU",
	},	
})

//REORDER
const REORDER_SETTING = setting({
	accid: "default",
	appid: "reorder",
	colid: "documents",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	ownerField : 'client',
	reorderAmount : 1,
	form: {
        referencePath: "default/reorder/forms/H8Y34pKpwr2Be83hmiYY",
	},
	mapItems: {
		referencePath: "default/reorder/grids/dL5cEz067vFsL3ItTPYD",
	}
});

//ORDER EXPRESS
const ORDER_EXPRESS_SETTING = setting({
	accid: "default",
	appid: "orderExpress",
	colid: "documentsOrderExpress",
	platform: platform,
	map: true,
	orderBy: "postdate",
	asc: false,
	form: {
        referencePath: "default/orderExpress/forms/OlXAFUDY2F91cpijO8nx",
	},
	mapItems: {
		referencePath: "default/orderExpress/grids/ZUGg0KKetwIWaAbTevL6",
	},
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
});

// COLLECTION
const COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/QGTO90ReQm6BYZcf5lb5",
	},
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
});

const SLUG_COLLECTION_SETTING = setting({
	accid: "default",
	appid: "collection",
	colid: "documentsCollection",
	platform: platform,
	orderBy: "order",
	map: true,
	level:1,
	mapItems: {
	  	referencePath: "default/collection/grids/SKJuzFlQ97rVmti64rzg",
	},
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
});

//PAGE
const PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/iBqwfjj1mYhVKHRnUuTn",
	},
});

//HOME PAGE
const HOME_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	orderBy: "order",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/yRP915cQD7mknZnQfaTR",
	},
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
	id : "9shj1WgF0i3yT9pdqWLz",
});

// PAGE PIX
const PAGE_PIX_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/E3GhDpwHdLEcxooKEKWO",
	},
	document: {
		referencePath: "default/page/documents/48gYTdoizfdU6Wx7hcyA",
	},
});

// AUTH
const AUTH_SETTING = setting({
	accid: "default",
	appid: "users",
	colid: "documents",
	platform: platform,
	form: {
		referencePath: "default/users/forms/5BMnB6DwQDHZ0ir8zatM",
	},
	map: true,
	mapItems: {
	  	referencePath: "default/users/grids/EuTmW4Wt3j85mVdqkJbt",
	},	
});

//CART
const CART_SETTING = setting({
	accid: "default",
	appid: "cart",
	colid: "documents",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/cart/forms/yi8sxDEbExlshNIXREV3",
	},
	mapItems: {
		referencePath: "default/cart/grids/PHQMj7Kv5ImTd7TW5RdT",
	},
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
	giftPage : {
		referencePath : 'default/page/documents/GDNnMlcjmCuhBbm1MDAo'
	},
});

// ANALYTICS
const ANALYTICS_SETTING = setting({
	accid: "default",
	appid: "analytics",
	colid: "documents",
	platform: platform,
	form: {
	  	referencePath: "default/analytics/forms/Ri9eeK5NsSlGCEsq7eeJ",
	},	
	map: true,
	mapItems: {
	  	referencePath: "default/analytics/grids/RlHy1iwUHPjpmlGZ9Bh7",
	},		
});

// SHIPPING
const SHIPPING_SETTING = setting({
	accid: "default",
	appid: "shippings",
	colid: "documents",
	platform: platform,
});

//COLOR VARIANT
const COLOR_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/xBniuzdnLDsoRKDsWii3",
	},
	document : {
		referencePath: "default/variant/documents/r3CXfQYFxinbVjakJsLA",		
	}
});

//SIZE VARIANT
const SIZE_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/xBniuzdnLDsoRKDsWii3",
	},
	document : {
		referencePath: "default/variant/documents/KRUQMmVU3bOsl59ECSwb",		
	}
});

//BATH VARIANT
const BATH_VARIANT_SETTING = setting({
	accid: "default",
	appid: "variant",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/variant/grids/xBniuzdnLDsoRKDsWii3",
	},
	document : {
		referencePath: "default/variant/documents/IuzZHV8p9eFikKglu8Go",		
	}
});

const LOOKS_SETTING = setting({
	accid: "default",
	appid: "looks",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
	mapItems: {
		referencePath: "default/looks/grids/zv1PMrXajkat61MmRwax",
	},
});

const LOOK_SLUG_SETTING = setting({
	accid: "default",
	appid: "looks",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "postdate",
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
	mapItems: {
		referencePath: "default/looks/grids/eFmaAYtFcAFbCOfYsDJK",
	},
});

const PARTIAL_PRODUCT_VARIANT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid : "documents",
	// appid: "productsVariant",
	// colid: "documentsProductsVariant",
	level: 1,
	map: true,
	asc: false,
	orderBy: "order",
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
	mapItems: {
		//referencePath: "default/product/grids/yzKnpJygzVLDVbN14pTe",
		referencePath: "default/productsVariant/grids/oTmwi02N3ZrDc6LbA4at",
	},
});

//FULL PRODUCT
const FULL_PRODUCT_SETTING = setting({
	accid: "default",
	appid: "product",
	colid: "documents",
	level: 1,
	map: true,
	asc: false,
	orderBy: "order",
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
	mapItems: {
		referencePath: "default/product/grids/ZUnonLswnK67KE4lnDmg",
	},
});

//SLUG PRODUCT
const SLUG_PRODUCT_SETTING = setting({
	accid : "default",
	appid : "product",
	colid : "documents",
	level : 1,
	map : true,
	asc : false,
	orderBy : "postdate",
	mapItems : {
		referencePath: "default/product/grids/4s9UG3dHM2jiKetLRULH",
	},
});

// OPTION
const OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/UKO4aIcuHYMLo2Fa5foT",
	},	
});

// PAYMENT METHODS OPTION
const PAYMENT_METHODS_OPTION_SETTING = setting({
	accid	 : "default",
	appid	 : "option",
	colid	 : "documents",
	platform : platform,	
	document: {
		referencePath: "default/option/documents/kdEtgv8BEwVRju3W5gmD",
  	},
	map: true,
	mapItems: {
	  	referencePath: "default/option/grids/UKO4aIcuHYMLo2Fa5foT",
	},	
});

// ERRORS
const ERROS_SETTING = setting({
	accid: "default",
	appid: "erros",
	colid: "documentsErros",
	platform: platform,
	map: true,
	form: {
		referencePath: "default/erros/forms/5l4BPQbTLWgDAWwovzkK",
	},
	mapItems: {
		referencePath: "default/erros/grids/m9NCRIpHC0ALVX7RCdrr",
	},
});

//COMMET
const COMMET_SETTING = setting({
	accid: "default",
	appid: "comment",
	colid: "documents",
	platform: platform,
	orderBy: "postdate",
	asc:false,
	map: true,
	mapItems: {
		referencePath: "default/comment/grids/lmh6u5Z5Yv1avBsIHUkI",
	},
	form: {
		referencePath: "default/comment/forms/RUHBFkTuTsKGOiVBbbVr",
	},
});

const REORDER_INFO: any = {
	name: "Solicitar Troca",
	labelNoteClient: "Motivo da troca",
  };

const REASONS_REORDER_OPTION = OPTION_SETTING.merge({
	id : 'WLE6fdRpXUgvrGOuKUzQ'
});

const REORDER_TYPE_OPTION = OPTION_SETTING.merge({
	id : 'jwk0vI8LG7C4Dii1LvUI'
});

//PACKAGING
const PACKAGING_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	orderBy: "postdate",
	asc:false,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/vdAlng29m0dd5WGjyQt6",
	},
	document: {
		referencePath: "default/page/documents/3iMSX1T9zWdofFq7AM4T"
	}
});

const GIFTS_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	document : {
		referencePath : 'default/page/documents/GDNnMlcjmCuhBbm1MDAo'
	},
	mapItems: {
		referencePath: "default/page/grids/6P1JtnexpX7JrU16yc9C",
	},
	stockName: {
		referencePath: "default/stockName/documents/RWPJyQSODWvXIzk38aoD",
	},
	priceName: {
		referencePath: "default/priceName/documents/yeyR31qZsKBuq9O7hoNf",
	},
});

//POPUP PAGE
const POPUP_PAGE_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems: {
		referencePath: "default/page/grids/RjLPCfEbF9Rt6xSCeqoI",
	},
	document: {
		referencePath: "default/page/documents/wlVcqF2cw3lnDTJlKvq1"
	}
});

//FEATURES PRODUCTS
const FEATRES_PRODUCTS_SETTING = setting({
	accid: "default",
	appid: "page",
	colid: "documents",
	platform: platform,
	map: true,
	mapItems : {
		referencePath: "default/page/grids/DV1meOSt86FdKf3hFZQG",
	},
	document: {
		referencePath: "default/page/documents/lyLVbjWXNw76ig9myMRd",
	}
});

export {
	REORDER_INFO,
	TRACK_SETTING,
	REORDER_TYPE_OPTION,
	REASONS_REORDER_OPTION,
	ORDER_SETTING,
	REORDER_SETTING,
	ORDER_EXPRESS_SETTING,
    MENU_FOOTER_STATIC,
	THEME_SETTING,
    ACCOUNT_SETTING,
    TOP_HEADER_PAGE_SETTING,
    MENU_SETTING,
	CATEGORY_SETTING,
	SLUG_CATEGORY_SETTING,
	COLLECTION_SETTING,
	SLUG_COLLECTION_SETTING,
    PAGE_SETTING,
    HOME_PAGE_SETTING,
	PAGE_PIX_SETTING,
    AUTH_SETTING,
    CART_SETTING,
	ANALYTICS_SETTING,
	SHIPPING_SETTING,
	COLOR_VARIANT_SETTING,
	SIZE_VARIANT_SETTING,
	BATH_VARIANT_SETTING,
	LOOKS_SETTING,
	LOOK_SLUG_SETTING,
	PARTIAL_PRODUCT_VARIANT_SETTING,
    FULL_PRODUCT_SETTING,
    SLUG_PRODUCT_SETTING,
	PAYMENT_METHODS_OPTION_SETTING,
	GATEWAY_SETTING,
	ERROS_SETTING,
	COMMET_SETTING,
	PACKAGING_SETTING,
	GIFTS_PAGE_SETTING,
	POPUP_PAGE_SETTING,
	FEATRES_PRODUCTS_SETTING,
}