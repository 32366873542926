import { SEO } from "../core-nextv3/util/seo";
import { calls } from "../core-nextv3/util/call.api";
import { getAccount } from "../core-nextv3/account/account.api";
import { collectionDocument, getDocument } from "../core-nextv3/document/document.api";
import { ACCOUNT_SETTING, CATEGORY_SETTING, GATEWAY_SETTING, MENU_SETTING, PACKAGING_SETTING, POPUP_PAGE_SETTING, TOP_HEADER_PAGE_SETTING } from "../setting/setting";
import { getProduct } from "../core-nextv3/product/product.api";

export default async function withHeader(propsFunc: any) 
{
	const [account, categories, menu, topHeader, packaging, gateway, popup] = await calls(
		getAccount(ACCOUNT_SETTING),
		collectionDocument(CATEGORY_SETTING.merge({
			where: [
				{
					field: "_level",
					operator: "==",
					value: 1,
				},
			],
		}
		)),
		collectionDocument(MENU_SETTING.merge({
			where: [
				{
					field: "_level",
					operator: "==",
					value: 1,
				},
			],
		}
		)),
		getDocument(
			TOP_HEADER_PAGE_SETTING.merge({
			document: {
				referencePath: "default/page/documents/XnXu9R0l14CYHXZ0o9B2",
			},
		})),
		getProduct(PACKAGING_SETTING),
		getProduct(GATEWAY_SETTING),
		getDocument(POPUP_PAGE_SETTING),
	);

	const props = {
		account	   : account?.data || {},
		seo 	   : SEO().mergeAccount(account?.data),
		categories : categories?.collection || [],
		menu	   : menu?.collection || [],
		topHeader  : topHeader?.data || {},
		packaging  : packaging?.data || {},
		gateway    : gateway?.data || {},
		popup      : popup.data || {},
	};

	const pageStaticProps = await propsFunc(props);
	pageStaticProps.props = { ...props, ...pageStaticProps.props };

	return pageStaticProps;
}
