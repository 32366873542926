import { THEME_SETTING } from "@/setting/setting";
import styles from "./styles.module.scss";
import Image from "next/image";

export const Certificates = () => {
  return (
    <section className={styles.certificates}>
      {/* <h1>Certificados</h1> */}
      <div className={styles.certificatesList}>
        <img
          // width={2}
          // height={32}
          // priority
          src="/assets/icons/pagarme.svg"
          alt="Pagarme"
        />
        <img
          // width={2}
          // height={32}
          // priority
          src="/assets/icons/letsencrypt.svg"
          alt="Let's Encrypt"
        />
        <a href="https://binext.com.br" target="_blank" rel="noreferrer">
          <img className={styles.binext} src="/assets/binext_logo_gray.png" alt="BINEXT" />
        </a>
        {!THEME_SETTING?.disabledLogoUpCommerce &&
          <img className={styles.binext} src="/assets/up-commerce-logo.png" alt="Up-Commerce" />
        }
      </div>
    </section>
  );
};
