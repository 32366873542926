import styles from "./styles.module.scss";
import { useState } from "react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Collapse } from "@material-ui/core";

export const AccordionSubMenuItemChild = ({ children, title, number }: any) => {
    const router = useRouter();
    const [toggle, setToggle] = useState(false)

    return (
        <div 
            className={styles.menuItemChild}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setToggle(!toggle) 
            }}
        >
            
            <p className={styles.accordionTitle}>
                {number ? <span>{number}</span> : null}

                {title}
                {toggle ? (
                    <IoIosArrowDown className={styles.accordionSvg} />
                ) : (
                    <IoIosArrowForward className={styles.accordionSvg} />
                )}
            </p>

            <Collapse in={toggle} sx={{ p:"1rem" }} mountOnEnter unmountOnExit>
                {children}
            </Collapse>
        </div>
    );
}