import styles from "./styles.module.scss";
import { BsWhatsapp } from "react-icons/bs";
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { removePhoneMask } from "../../core-nextv3/util/util";
import { THEME_SETTING } from "@/setting/setting";

export const IconsLink = ({ account }: any) => {
  return (
    <div className={styles.iconsLinks}>
      {!THEME_SETTING?.disabledIconLinkThree &&
        <div
          className={styles.linkThreeIcon}
          onClick={() => window.open("https://linktr.ee/quebelamodaplus")
            // {
            //   tagManager4.registerEvent("contact", "whatsapp-button", "Contato", 0, null);
            //   window.open(
            //     `https://api.whatsapp.com/send?phone=${removePhoneMask(account?.whatsapp)}&text=Olá, gostaria de tirar algumas duvidas.`,
            //     "_blank"
            //   )
            // }
          }
        >
          <img src="/assets/img/linktree.png" alt="" />
        </div>
      }

      <div
        className={styles.whatsAppIcon}
        onClick={() =>
          {
            tagManager4.registerEvent("contact", "whatsapp-button", "Contato", 0, null);
            window.open(
              `https://api.whatsapp.com/send?phone=${removePhoneMask(account?.whatsapp)}&text=Olá, gostaria de tirar algumas duvidas.`,
              "_blank"
            )
          }
        }
      >
        <BsWhatsapp />
      </div>
    </div>
  );
};
