import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { useState } from "react";
import { randomNumber, randomString } from "../../core-nextv3/util/util";
import { AccordionSubMenuItem } from "../AccordionSubMenuItem";
import { AccordionSubMenuItemChild } from "../AccordionSubMenuItemChild";
import { IoIosArrowForward } from "react-icons/io";

export const MenuTypeMobile = ({ menu, setHeaderMobile, colorTable, item }: any) => 
{
//   console.log("menuTypeMobile", item);
  const router = useRouter();
  const [subMenu, setSubMenu] = useState(false);
  const [subMenuStones, setSubMenuStones] = useState(false);
  const [subMenuProducts, setSubMenuProducts] = useState(false);

  const [openSubMenu, setOpenSubMenu] = useState(false);

  const pushMenu = (path: string) => {
    window.scrollTo(0, 0);
    router.push(path);
    setHeaderMobile(false);
  };

  const getMenu = (item: any) => {
    switch (item?.type?.value) {
      case "path":
        return (
          <>
		  	{item?._children && item?._children?.length > 0 ? (
                    <li>
                       <p
							onClick={() => setOpenSubMenu(!openSubMenu)}
							className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' ' + styles.subMenuNavigation}
						>
                            {item.name}
                            <IoIosArrowForward />
                        </p>
                        {openSubMenu && <div className={(styles.subMenuChildren) + ' ' + (item.differentFont ? styles.differentFont : '')}>
                             <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
							{item?.addSeeAll == false &&
								<a href={`${item.link}`}>
                                    Ver Todos
                                </a>
                        	}
                            </p>
                            {item?._children?.map((child: any) => (
                                child.status && <div
                                key={randomString(8)}>
                                    {getMenu(child)}
                                </div>
                            ))}
                        </div>}
                    </li>
                ) : (
					          <p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
                        <a href={`${item.link}`}>
                            {item?.name}
                        </a>
                    </p>
                )}
          </>
        );
      case "category":
		return <>
			{item?.category?._children?.length > 0 ? (
				item?.category?.status &&
				<li>
					<AccordionSubMenuItem title={item?.name}>
						<p>
							<a
								onClick={() => pushMenu(`/categoria/${item?.category.slug}/`)}
								key={randomString(8)}
							>
								Ver Todos
							</a>
						</p>

						{item?.category?._children?.map((child: any) => (
							<>
								{
								child?._children && child?._children?.length > 0 ?
									<>
										<AccordionSubMenuItemChild title={child?.name}>
											<p>
												<a
													onClick={() => pushMenu(`/categoria/${child.slug}/`)}
													key={randomString(8)}
												>
													Ver Todos
												</a>
											</p>
											{child?._children?.map((subChild:any) =>
												(
													<p
														key={subChild?.any}
													>
														<p>
															<a
																onClick={() => pushMenu(`/categoria/${subChild.slug}/`)}
																key={randomString(8)}
															>
																{subChild.name}
															</a>
														</p>
													</p>
												))
											}
										</AccordionSubMenuItemChild>
									</>
								:
									<p
										key={child?.any}
									>
										<p>
											<a
												onClick={() => pushMenu(`/categoria/${child.slug}/`)}
												key={randomString(8)}
											>
												{child.name}
											</a>
										</p>
									</p>
								}
							</>
						))}
					</AccordionSubMenuItem>					
				</li>
			) : (
				<p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
					<a href={`/categoria/${item?.category?.slug}/`} onClick={() => { setHeaderMobile(false)}}>
						{item?.name}
					</a>
				</p>
			)}
		</>                  
      case "collection":
		return <>{item?.collection?._children?.length > 0 ? (
				<li>
				<p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' ' + styles.subMenuNavigation} onClick={() => setOpenSubMenu(!openSubMenu)}>
						{item.name}
						<IoIosArrowForward />
					</p>
					{openSubMenu && <div className={(styles.subMenuChildren) + ' ' + (item.differentFont ? styles.differentFont : '')}>
						<p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
							<a
								href={`/colecao/${item?.collection?.slug}/`} 
								onClick={() => { setHeaderMobile(false)}}
							>
								Ver Todos
							</a>
						</p>
						{item?._children?.map((child: any) => (
							child.status && <div
							key={randomString(8)}>
								{getMenu(child)}
							</div>
						))}
					</div>}
				</li>
			) : (
				<p className={(item.highlight ? styles.highlight : '') + ' ' + (item.bold ? styles.bold : '') + ' ' + (item.differentFont ? styles.differentFont : '') + ' menu-' + item.id}>
					<a href={`/colecao/${item?.collection?.slug}/`} onClick={() => { setHeaderMobile(false)}}>
						{item?.name}
					</a>
				</p>
			)}
		</>
      default:
        return null;
    }
  };

  return (
    <div className={styles.menuTypeMobile}>
		    {item.status && getMenu(item)}      
    </div>
  );
};
