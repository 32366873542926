import styles from "./styles.module.scss";
import React, { useEffect } from "react";
import { useState } from "react";
import { MenuType } from "../MenuType";
import { useRouter } from "next/router";
import { BiSearch, BiWalletAlt } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { MenuTypeMobile } from "../MenuTypeMobile";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ModalBag from "../../component-nextv3/ModalBag";
import { headerAnimationMobile } from "../../animation";
import { AnimatePresence, motion } from "framer-motion";
import { CarouselTopHeader } from "../CarouselTopHeader";
import { SearchBar } from "../../component-nextv3/SearchBar";
import { useGetCart } from "../../core-nextv3/cart/cart.api";
import { FiUser, FiShoppingBag, FiMenu } from "react-icons/fi";
import { useGetLoggedAuth } from "../../core-nextv3/auth/auth.api";
import { AUTH_SETTING, CART_SETTING } from "../../setting/setting";
import { currencyMask } from "../../core-nextv3/util/mask";
import { useCore } from "../../core-nextv3/core/core";
import { useSetUTMParameters } from "@/core-nextv3/util/util";

interface HeaderProps {
  menu: any;
  topHeader: any;
  colorTable: any;
  gateway:any;
  cartPage:any
}

export const Header: React.FC<HeaderProps> = ({ topHeader, menu, colorTable, gateway, cartPage }) => 
{
  // console.log('menu', menu)
  const router                                            = useRouter();
  const [searchBar, setSearchBar]                         = useState(false);
  const [headerMobile, setHeaderMobile]                   = useState(false);
  const { user, setUser, cart, setCart, isOpenCart, setIsOpenCart, setInstallmentRule, setColorTable } = useCore();
  // console.log('user', user)

  //console.error('xxx', cartPage);

	useGetLoggedAuth(AUTH_SETTING, (data: any) => 
	{
		  setUser(data);
	});

	useGetCart(CART_SETTING, (data: any) => 
	{
		  setCart(data);
	});

  useSetUTMParameters();

  useEffect(() =>
	{
		if(gateway && gateway.installmentRule)
		{
			  setInstallmentRule(gateway.installmentRule);
		}    

	}, [gateway])

  useEffect(() =>
	{
		if(colorTable)
		{
			  setColorTable(colorTable);
		}
	}, [colorTable])

	const doLogin = () =>
	{
		if(user)
		{
			router.push("/perfil");
		}
		else
		{
			router.push("/login");
		}		
	}

    return(
        <>
        <div className={styles.header}>
        {topHeader?.published === true && (
          <CarouselTopHeader topHeader={topHeader} />
        )}
        <div className={styles.content}>
          <div className={styles.top}>

            <div></div>

            <div className={styles.image}>
              <img
                onClick={() => router.push("/")}
                src="/assets/logo.png"
                alt=""
              />
            </div>

            <div className={styles.icons}>
              <div className={styles.userIcon}>
                {user?.points > 0 && <span><BiWalletAlt />{currencyMask(user?.points)}</span>}
                <FiUser onClick={() => doLogin()} />
              </div>
              <BiSearch onClick={() => setSearchBar(true)} />
              <div className={styles.bagIcon}>
                <FiShoppingBag onClick={() => setIsOpenCart(true)} />
                <span>{cart?.items?.length || 0}</span>
              </div>
            </div>
          </div>

          <nav>
            <div className={styles.links}>
              <MenuType menu={menu} colorTable={colorTable} />
            </div>
          </nav>
        </div>
      </div>

      <div className={styles.headerMobile}>
        <CarouselTopHeader topHeader={topHeader} />
        <div className={styles.content}>
          <div className={styles.right}>
            <FiMenu onClick={() => setHeaderMobile(true)} />
          </div>
          <div className={styles.center}> 
            <img
                onClick={() => router.push("/")}
                src="/assets/logo.png"
                alt=""
              />
          </div>
          <div className={styles.icons}>
            <div className={styles.userIcon}>
              {user?.points > 0 && <span><BiWalletAlt />{currencyMask(user?.points)}</span>}
              <FiUser onClick={() => doLogin()} />
            </div>
            <BiSearch onClick={() => setSearchBar(true)} />
            <div className={styles.bagIcon}>
              <FiShoppingBag onClick={() => setIsOpenCart(true)} />
              <span>{cart?.items?.length || 0}</span>
            </div>
          </div>
        </div>
      </div>

      {headerMobile ? (
        <motion.div
          className={styles.modalHeader}
          variants={headerAnimationMobile}
          initial="hidden"
          animate="show"
          exit="exit"
          onClick={() => setHeaderMobile(false)}
        >
          <div className={styles.content} onClick={(e) => e.stopPropagation()}>
            <AiOutlineCloseCircle
              onClick={() => setHeaderMobile(false)}
              className={styles.close}
            />

            <div
              className={styles.user}
              onClick={() => {
                setHeaderMobile(false), doLogin();
              }}
            >
              <FaRegUserCircle />
              {user ? <p>{user?.name}</p> : <p>Acessar</p>}
            </div>
            <div className={styles.links}>
              {menu?.map((item: any, index: any) => (
                <MenuTypeMobile
                  key={index}
                  item={item}
                  menu={menu}
                  colorTable={colorTable}
                  setHeaderMobile={setHeaderMobile}
                  />
              ))}
            </div>
          </div>
        </motion.div>
      ) : null}

      <AnimatePresence
        initial={false}
        mode='wait'
        onExitComplete={() => null}
      >
        {searchBar && <SearchBar setSearchBar={setSearchBar} />}
        {isOpenCart && <ModalBag cartPage={cartPage}/>}
      </AnimatePresence>
        </>
    );
}