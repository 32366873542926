import styles from "./styles.module.scss";
import { useRef } from "react";
import Slider from "react-slick";
import { BsTag } from "react-icons/bs";

export const CarouselTopHeader = ({ topHeader }: any) => {
  const customSlider = useRef<any>();

  var sliderOpts = {
    infinite: true,
    arrows: false,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className={styles.carouselTopHeader}>
      <div className={styles.content}>
        <Slider
          {...sliderOpts}
          className="carouselTopHeaderSlide"
          ref={customSlider}
        >
          {topHeader?.list?.map((item: any) => (
            <div key={item?.name} className="topHeaderSlide">
              <h1><BsTag />{item?.name}</h1>
            </div>
          ))} 
        </Slider>
      </div>
    </div>
  );
};
