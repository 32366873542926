import styles from "./styles.module.scss";
import { useRouter } from "next/router";
import { randomString } from "../../core-nextv3/util/util";

export const MenuType = ({ menu }: any) => 
{
    // console.log("vldnvwelk", menu)
    const { push: router } = useRouter();

    const getMenu = (item: any) => 
    {
        switch (item?.type?.value) 
        {
          case "path":
            return (
              <>
              {
                item?._children && item?._children?.length > 0 ?
                  <li className={styles.dropdown}>
                    <a href={(`${item?.link}`)}>
                        {item?.name}
                    </a>
                    <ul className={styles.dropdownMenu}>
                      {item?.link && <li
                        key={randomString(8)}
                      >
                        {item?.addSeeAll == false &&
                          <a href={(`${item?.link}`)}>
                            Ver Todos
                          </a>
                        }
                      </li>}
                      {
                        item?._children?.map((child:any, key:any) => (
                            getMenu(child)
                        ))
                      }
                    </ul>
                  </li> 
                  :
                  <li>
                      <a href={(`${item?.link}`)}>
                        {item?.name}
                      </a>
                  </li> 
              }
              </>
            );
          case "category":
            return (
              <>
              {
                item?.category?._children && item?.category?._children?.length > 0 ?
                  <li className={styles.dropdown}>
                    <a href={(`/categoria/${item?.category?.slug}/`)}>
                      {item?.category?.name}
                    </a>                    
                    <ul className={styles.dropdownMenu}>
                      <li
                        key={randomString(8)}
                      >
                          <a href={(`/categoria/${item?.category?.slug}`)}>
                            Ver Todos
                          </a>
                      </li>
                      {
                        item?.category?._children?.map((child:any, key:any) => (
                            getMenu({ type : { value : 'category' }, category : child })
                        ))
                      }
                    </ul>
                  </li> 
                  :
                  <li>
                      <a href={(`/categoria/${item?.category?.slug}/`)}>
                          {item?.category?.name}
                      </a>
                  </li> 
              }                
              </>
            );
          case "collection":
            return (
              <>
                {
                    item?.collection?._children && item?.collection?._children?.length > 0 ?
                    <li className={styles.dropdown}>
                        <a href={(`/colecao/${item?.collection?.slug}/`)}>
                            {item?.name}
                        </a>
                        <ul className={styles.dropdownMenu}>
                          {item?.link && <li
                            key={randomString(8)}
                          >
                            <a href={(`/colecao/${item?.collection?.slug}/`)}>
                              Ver Todos
                            </a>
                          </li>}
                          {
                            item?._children?.map((child:any, key:any) => (
                                getMenu(child)
                            ))
                          }
                        </ul>
                      </li> 
                      :
                      <li>
                          <a href={(`/colecao/${item?.collection?.slug}/`)}>
                            {item?.name}
                          </a>
                      </li> 
                  }
              </>
            );
          default:
            return null;
        }
    };

    return (
      <div className={styles.menuType}>
        {menu?.map(
          (item: any) =>
            item.status && (
              <ul className={styles.menu} key={randomString(8)}>
                {getMenu(item)}
              </ul>                           
            )
        )}
      </div>
    );
};
