//import axios from "axios";
import { checkCnpj } from "../cnpj/cnpjs.api";
import { call } from "./call.api";
import Types from "../type";
import { TDate } from "../model/TDate";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { isAfter } from "date-fns";

// const isInAppBrowser = () =>
// {
//     const ua = navigator.userAgent || navigator.vendor || window.opera;

//     // Detectar navegadores embutidos comuns
//     if (/Instagram/.test(ua) || /FBAN/.test(ua) || /FBAV/.test(ua) || /Twitter/.test(ua)) 
//     {
//         return true;
//     }

//     // Adicione outros navegadores embutidos comuns aqui, se necessário
//     return false;
// }

const isInAppBrowser = () => 
{
    const ua = navigator.userAgent || navigator.vendor || window.opera;

    // Converte o user agent para minúsculas para garantir a detecção correta, independentemente do caso
    const lowerUa = ua.toLowerCase();

    // Detecta navegadores embutidos comuns, incluindo variações de user agent
    if (
        lowerUa.includes('instagram') ||
        lowerUa.includes('fban') ||
        lowerUa.includes('fbav') ||
        lowerUa.includes('twitter') ||
        lowerUa.includes('line') ||
        lowerUa.includes('snapchat')
    ) {
        return true;
    }

    // Adicione outros navegadores embutidos comuns aqui, se necessário
    return false;
};

const capitalizeFirstLetter = (value:string) =>
{
    return value.replace(/^\w/, (c) => c.toUpperCase());
}

const validateQuerySlug = (query:any) =>
{
    if ((typeof query?.slug == 'string' && query?.slug) ||
	   (typeof query?.slug == 'object' && query?.slug.length > 0))
    {
        return true;
    }
    else
    {
        return false;
    }
}

const randomString = (length: number) => 
{
    let text       = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++) 
    {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};

const getRecaptcha = async (action:string) => 
{
    return new Promise((resolve) => 
    {
        // PROBLEMA COM RECAPTCHA INTERNO DOS NAVEGADORES
        if(isInAppBrowser()) 
        {
            resolve(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY);
        }
        else if (typeof grecaptcha !== 'undefined') 
        {
            grecaptcha.enterprise.ready(async () => 
            {
                const token = await grecaptcha.enterprise.execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, { action: action });
                
                resolve(token);       
            });
        }
        else
        {
            resolve("");
        }
    })
};

const replaceDomain = (url:string, domainFrom:any, domainTo:any) => 
{   
    if (domainFrom && domainTo)
    {
        url = url.replace(domainFrom, domainTo);
    }

    return url;
};

const calculateIncreasePercentage = (originalValue:number, newValue:number) =>
{
    if (originalValue === 0) 
    {
        throw new Error("O valor inicial não pode ser zero.");
    }

    const increase = newValue - originalValue;
    return (increase / originalValue) * 100;
}

// UNIQUE USER
const useSetUTMParameters = (onSuccess?:any) => 
{
    const [ cookies, setCookie ] = useCookies([ "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "referrer" ]);
    const expires                = new Date()
    expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000));

    useEffect(() => 
    {
        // Obtém a string da URL
        const queryString = window.location.search;

        // Cria um objeto URLSearchParams para analisar os parâmetros
        const params = new URLSearchParams(queryString);

        // Obtém os valores dos parâmetros UTM
        const utmSource   = params.get("utm_source");
        const utmMedium   = params.get("utm_medium");
        const utmCampaign = params.get("utm_campaign");
        const utmTerm     = params.get("utm_term");
        const utmContent  = params.get("utm_content");   
        const referrer    = document.referrer || null;

        // Faça algo com os valores UTM
        console.log("UTM Source:",   utmSource);
        console.log("UTM Medium:",   utmMedium);
        console.log("UTM Campaign:", utmCampaign);
        console.log("UTM Term:",     utmTerm);
        console.log("UTM Content:",  utmContent);  
        
        const data : any = {
            utmSource   : utmSource,
            utmMedium   : utmMedium,
            utmCampaign : utmCampaign,
            utmTerm     : utmTerm,
            utmContent  : utmContent,
            referrer    : referrer,
        };

        if (utmSource)
        {
            setCookie("utm_source",   utmSource,   { path : "/", expires });
        }

        if (utmMedium)
        {
            setCookie("utm_medium",   utmMedium,   { path : "/", expires });
        }

        if (utmCampaign)
        {
            setCookie("utm_campaign", utmCampaign, { path : "/", expires });
        }

        if (utmTerm)
        {
            setCookie("utm_campaign", utmTerm, { path : "/", expires });
        }

        if (utmContent)
        {
            setCookie("utm_content",  utmContent,  { path : "/", expires });
        } 

        if (referrer && referrer.indexOf(window.location.host) > -1)
        {
            setCookie("referrer",  referrer,  { path : "/", expires });
        }     

        if (onSuccess)
        {
            onSuccess(data);
        }

    }, []);	
}

const useGetUTMParameters = (onSuccess:any) =>  
{
    const router                 = useRouter();
    const [ cookies, setCookie ] = useCookies([ "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "referrer" ]);

    useEffect(() => 
    {
        const data : any = {};

        if (cookies.utm_source)
        {
            data.utm_source = cookies.utm_source; 
        }

        if (cookies.utm_medium)
        {
            data.utm_medium = cookies.utm_medium; 
        }

        if (cookies.utm_campaign)
        {
            data.utm_campaign = cookies.utm_campaign; 
        }

        if (cookies.utm_term)
        {
            data.utm_term = cookies.utm_term; 
        }

        if (cookies.utm_content)
        {
            data.utm_content = cookies.utm_content; 
        }

        if (cookies.referrer)
        {
            data.referrer = cookies.referrer; 
        }

        onSuccess(data);

    }, [ router.asPath ]);	
}

const firstName = (value:string) => 
{   
    if (value)
    {
        return value.split(" ")[0];
    }
};

const generateKey = () => 
{   
    return randomString(8);
};

const objectToArray = (data:any) => 
{   
    const list = [];  

    for (const key in data)
    {
        list.push(data[key]);
    }

    return list;
};

const removeSlidesEmpty = (slides:any) => 
{   
    setTimeout(() => 
    {
        const slidesToRemove = slides.root.querySelectorAll(".splide__list .splide__slide:empty");

        slidesToRemove.forEach((slide:any) => 
        {
            slide.remove();
        });
        
        slides.refresh();  
    }, 500);
};

const showError = (result:any) => 
{   
    let message = "";
            
    if (result.error instanceof Object)
    {
        for (const key in result.error)
        {
            message = result.error[key];
            break;
        }
    }
    else
    {
        message = result.error;
    }

    return toast.error(message,
        {
            duration : 3000,
        });
};

const formatAddress = (address:any) => 
{
    if (address?.street)
    {
        return `${address?.street} - ${address?.district}, ${address?.city} - ${address?.state}`
    }   
    
    return "";
};

const formatDate = (value:any, mask="dd/MM/yyyy HH:mm:ss") => 
{
    const date = new TDate({ value : value });
    
    return date.format(mask);
};

const imageLoader = (image:any, width:number) => 
{
    let url = encodeURI(image?._url);

    if (width <= 480 && (image?._480x480 || image?._300x300)) 
    {
        url = image?._480x480 ? encodeURI(image?._480x480) : encodeURI(image?._300x300);        
    }
    else if (width <= 1024 && image?._1024x1024) 
    {
        url = encodeURI(image?._1024x1024);
    }
    else if (width <= 1920 && image?._1920x1920) 
    {
        url = encodeURI(image?._1920x1920);
    }    

    return url.replace(".webp", ".jpeg");
};

const customImageLoader = ({ src }:any) => 
{
    return src;
};

const cloudflareLoader = ({ src, width, quality = 75}:any) =>
{
    const params = [
        `width=${width}`,
        `quality=${quality}`,
        'format=auto',
    ];
    
    const paramsString = params.join(',');

    // Retorne a URL formatada para o Cloudflare
    return `${process.env.NEXT_PUBLIC_DOMAIN}/cdn-cgi/image/${paramsString}/${src}`;
}

const breakLine = (value:string) => 
{
    if (value)
    {
        return value.toString().replace(/(?:\r\n|\r|\n)/g, "<br>");
    }    

    return "";
}

const getDomainFromReferrer = () =>
{
    let value = "/";

    if (document.referrer)
    {         
        ///^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/igm
        const result : any = document.referrer.match(/[^w{3}\.]([a-zA-Z0-9]([a-zA-Z0-9\-]{0,65}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}/gim);

        if (result && result.length > 0)
        {
            value = result[0]   
        }
    }

    return value;
}

const sortArrayByProp = (array: any, prop: string) => 
{
    function SortArray(x: any, y: any) 
    {
        if (x[prop] < y[prop]) 
        {
            return -1;
        }

        if (x[prop] > y[prop]) 
        {
            return 1;
        }

        return 0;
    }

    const s = array?.sort(SortArray);

    return s;
};

const maxCharacters = (text:string, count:number) =>
{
    return text.slice(0, count) + (((text.length > count)) ? "..." : "");
}

const randomNumber = (length: number) => 
{
    let text       = "";
    const possible = "0123456789";

    for (let i = 0; i < length; i++) 
    {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
};

const innerHTML = (data: any) => 
{
    return { __html : data };
};

const stripHtmlTags = (value: string) => 
{
    if (!value) 
    {
        return "";
    }
    else 
    {
        // const value2 = value.toString();
        // return value2.replace(/<[^>]*>/g, "");
        // Primeiro substitui <br> e <br/> com um placeholder único que não será afetado
        let safeHtml = value.replace(/<br\s*\/?>/gi, "PLACEHOLDER_FOR_BR");

        // Remove todas as outras tags HTML
        safeHtml = safeHtml.replace(/<[^>]*>?/gm, "");

        // Restaura as quebras de linha substituindo o placeholder por <br>
        safeHtml = safeHtml.replace(/PLACEHOLDER_FOR_BR/g, "<br>");

        return safeHtml;
        
    }
};

const removePhoneMask = (value: string) => 
{
    if (!value) 
    {
        return "";
    }
    else 
    {
        const value2 = value.toString();
        return value2.replace(/[\(\)\.\+\s-]+/g, "");
    }
};

const removeAccents = (value: string) => 
{
    value = value.trim();
    //const value2 		= value.toLowerCase(); // NÃO PODE FAZER ISSO POR CONTA DA BUSCA FULL
    const accents: any =
    "ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ";
    const accents_out  = "AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg";
    const accents_map  = new Map();  

    for (const accent in accents) 
    {
        accents_map.set(
            accents.charCodeAt(accent),
            accents_out.charCodeAt(parseInt(accent))
        );
    }

    const nstr = new Array(value.length);
    let x, i;

    for (i = 0; i < nstr.length; i++) 
    {
        nstr[i] = accents_map.get((x = value.charCodeAt(i))) || x;
    }

    return String.fromCharCode.apply(null, nstr);
};

const insertUrlQuery = (key: any, value: any) => 
{
    if ((history as any).pushState) 
    {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
        window.history.pushState({ path : newurl }, "", newurl);
    }
};

const findDuplicates = (arr: any) => 
{
    const newArray = arr?.reduce((unique: any, o: any) => 
    {
        if (
            !unique.some((obj: any) => obj.label === o.label && obj.value === o.value)
        ) 
        {
            unique.push(o);
        }

        return unique;
    }, []);

    return newArray;
};

const objectParser = (object: any) => 
{
    const objtString: any = [];
    const obj             = Object.entries(object).forEach(([ key, value ]: any) => 
    {
        if (value.quantity > 0) 
        {
            objtString.push(`${key}`);
        }

    // ${value.quantity}
    });

    return objtString;
};

const formatCPF = (cpf: any) => 
{
    const newcpf = cpf.replace(/\./g, "").replace(/\-/g, "");
    let s        = newcpf + "";
    while (s.length < 11) s = "0" + s;
    return s;
};

const objectParserArray = (object: any) => 
{
    const objtString: any = [];
    const obj             = Object.entries(object).forEach(([ key, value ]: any) => 
    {
        if (value.quantity > 0) 
        {
            objtString.push([ key, value.quantity ]);
        }

    // ${value.quantity}
    });

    return objtString;
};

const pixToQrCode = async (data: any) => 
{
    const response  = await fetch(`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${data}`);
    const pixQrCode = await response.json();      

    return pixQrCode;
};

const buscaCep2 = async (cep: any) => 
{
  type AddressProps = {
    bairro: string;
    cep: string;
    complemento: string;
    localidade: string;
    logradouro: string;
    uf: string;
  };

  try 
  {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const address  = await response.json();
    
      if (!address.erro)
      {
          return {
              zipcode  : address.cep,
              city     : address.localidade,
              district : address.bairro,
              state    : address.uf,
              street   : address.logradouro,
              ibge     : address.ibge,
              country  : { id : "br", label : "Brasil", value : "br" }
          };  
      }
  } 
  catch (error) 
  {
      console.error(error);
  }
};

const buscaCep = async (cep: any, setAddress: any, setValue: any) => 
{
  type AddressProps = {
    bairro: string;
    cep: string;
    complemento: string;
    localidade: string;
    logradouro: string;
    uf: string;
  };

  try 
  {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const address  = await response.json();

      if (!address.erro)
      {
          setAddress(address);
          setValue("city", address.localidade);
          setValue("district", address.bairro);
          setValue("state", address.uf);
          setValue("street", address.logradouro);
      }
    
  }
  catch (error) 
  {
      console.error(error);
  }
};

const getCnpjInfo = async(cnpj:any, setCnpj:any, setValue:any) => 
{
    const memoCnpj = cnpj.replace(/[^\w\s]/gi, "");

    if (memoCnpj.length === 14)
    {
        const result = await checkCnpj({ cnpj : memoCnpj });

        if (result?.status)
        {
            const infos = result?.data;
            setCnpj(infos);
            setValue("stateinscription", infos?.incricao_estadual);
            setValue("socialReason", infos?.razao_social);
            setValue("storeName", infos?.nome_fantasia);
            setValue("fantasyName", infos?.nome_fantasia);
      
            // if(infos?.nome_fantasia !== null){
            //   setValue("storeName", infos?.nome_fantasia);
            // }else{
            //   setValue("storeName", infos?.razao_social);
            // }      
        }
    }
}

const updateQueryString = (key:string, value:string) =>
{
    const uri       = window.location.href;
    const re        = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = uri.indexOf("?") !== -1 ? "&" : "?";
    let newUrl;

    if (uri.match(re)) 
    {
        newUrl = uri.replace(re, "$1" + key + "=" + value + "$2");
    }
    else 
    {
        newUrl = uri + separator + key + "=" + value;
    }

    window.history.pushState("", "", newUrl);
}

const parseQueryHandler = (
    router: any,
    categories: any,
    colors: any,
    sizes: any,
    stores?: any,
) => 
{
    const filters: any            = [];
    const colorSizeFilters: any   = [];
    const minMaxPriceFilters: any = [];

    if (!router.query) 
    {
        return filters;
    }

    const colorValue = colors?.filter(
        (color: any) => color.value == router.query.cor
    );

    const sizeValue = sizes?.filter(
        (size: any) => size.value == router.query.tamanho
    );

    if (stores && router.query.marca) 
    {
        const store = stores.filter(
            (store: any) => store.name == router.query.marca
        );

        colorSizeFilters.push({
            field    : "store",
            operator : "==",
            value    : {
                referencePath : store[0].referencePath,
            }
        });
    }

    if (router.query.categoria) 
    {
        const pageCategory = categories.filter(
            (category: any) => category.name == router.query.categoria
        );

        colorSizeFilters.push({
            field    : "indexes.categoriesxcolorxsize",
            operator : "combine",
            value    : [
                {
                    referencePath : pageCategory[0].referencePath,
                },
            ],
        });
    }

    if (router.query.cor) 
    {
        colorSizeFilters.push({
            field    : "indexes.categoriesxcolorxsize",
            operator : "combine",
            value    : [ ...colorValue ],
        });
    }

    if (router.query.tamanho) 
    {
        colorSizeFilters.push({
            field    : "indexes.categoriesxcolorxsize",
            operator : "combine",
            value    : [ ...sizeValue ],
        });
    }

    if (router.query.precoMin) 
    {
        minMaxPriceFilters.push({
            field    : "indexes.price",
            operator : ">=",
            value    : Number(router.query.precoMin),
        });
    }

    if (router.query.precoMax) 
    {
        minMaxPriceFilters.push({
            field    : "indexes.price",
            operator : "<",
            value    : Number(router.query.precoMax),
        });
    }

    if (router.query) 
    {
        filters.push(...colorSizeFilters, ...minMaxPriceFilters);
    }

    return filters;
};

const orderHandler = (router: any) => 
{
    let order = {};

    if (router.query.ordenacao === "precoAsc") 
    {
        return (order = { orderBy : "indexes.price", asc : true });
    }
    else if (router.query.ordenacao === "precoDec") 
    {
        return (order = { orderBy : "indexes.price", asc : false });
    }
    else if (router.query.ordenacao === "dataAsc") 
    {
        return (order = { orderBy : "postdate", asc : true });
    }
    else if (router.query.ordenacao === "dataDec") 
    {
        return (order = { orderBy : "postdate", asc : false });
    }
    else if (router.query.ordenacao == "range") 
    {
        return (order = { orderBy : "indexes.price" });
    }
    else 
    {
        order = {};
    }

    return order;
};

// REMOVE CARACTERES VAZIO E STILOS
const clearHtml = (content: string) => 
{
    if (content == null || undefined) return;

    const regex  = /style="(.*?)"/g;
    const regex2 = /style='(.*?)'/g;
    const subst  = "";

    let result = content.replace(regex, subst);
    result     = result.replace(regex2, subst);
    result     = result.replace(/\&nbsp;/g, "");

    return result;
};

const textParser = (content: string) => 
{
    if (content == null || undefined) return;

    const regex  = /style="(.*?)"/g;
    const regex2 = /style='(.*?)'/g;
    const subst  = "";

    let result = content.replace(regex, subst);
    result     = result.replace(regex2, subst);
    result     = result.replace(/<\/?[^>]+(>|$)/g, "");
    result     = result.replace(/(<([^>]+)>)/gi, "");
    result     = result.replace(/\&nbsp;/g, "");

    return result;
};

const getTotalItems = (items: any) => 
{
    return items?.reduce((acc: any, item: any) => 
    {
        return (acc = acc + item.quantity);
    }, 0);
};

const getTotalItemsValue = (items: any) => 
{
    return items?.reduce((acc: any, item: any) => 
    {
        return (acc = acc + item.total);
    }, 0);
};

const  queryStringToObject = (query:string) => 
{
    if (query.indexOf("?") > -1)
    {
        query = query.split("?")[1];
    }
    
    const pairs        = query.split("&");
    const result : any = {};
    
    pairs.forEach(function(pair:any) 
    {
        pair            = pair.split("=");
        result[pair[0]] = decodeURIComponent(pair[1] || "");
    });

    return result;
}

const getProductsByStore = (items: any) => 
{
    const stores: any = {};

    items.map((item: any, index: any) => 
    {
        if (!stores[item?.product?.store?.name]) 
        {
            stores[item?.product?.store?.name]       = {};
            stores[item?.product?.store?.name].items = [];
        }

        stores[item?.product?.store?.name].store = item?.product?.store;
        stores[item?.product?.store?.name].items.push(item);
    });
    return stores;
};

const getPathFromUrl = (url:string) =>
{  
    return url.replace(/(\?.*)|(#.*)/g, "");
}

const hasBack = () =>
{  
    return window.history.length > 2
}

const classThatStopped = (childLesson: any, selectedVideo: any) =>
{
    if (childLesson && selectedVideo && childLesson.id === selectedVideo.id ) 
    {
        return "activeClass"
    }
    else 
    {
        return "classNotActive"
    }
}

const loadRSS = (setting:any) => 
{
    const data = {
		  url : setting.url,
    };

    return call(Types.RSS_UTIL_SERVER, data)
}

const clearCacheByTag = (setting:any) => 
{
    return call(Types.CLEAR_CACHE_BY_TAG_UTIL_SERVER, setting)
}

const downDate = (date:any, callback:any) => 
{
    const intervalo = setInterval(() => 
    {
        // console.warn("aqui", new Date(), date, isAfter(new Date(), date))
        if (isAfter(new Date(), date)) 
        {
            callback();
            clearInterval(intervalo);
        }
    }, 1000); 
}

const getAccid = () => 
{
    return process.env.NEXT_PUBLIC_ACCID;
}

const getUrlSite = () =>
{
    const newurl =
      window.location.protocol + "//" + window.location.host

    return newurl;
}

export {
    getProductsByStore,
    getTotalItems,
    randomString,
    orderHandler,
    parseQueryHandler,
    sortArrayByProp,
    randomNumber,
    stripHtmlTags,
    removeAccents,
    insertUrlQuery,
    findDuplicates,
    formatCPF,
    objectParser,
    objectParserArray,
    pixToQrCode,
    buscaCep2,
    buscaCep,
    innerHTML,
    textParser,
    getTotalItemsValue,
    updateQueryString,
    maxCharacters,
    removePhoneMask,
    hasBack,
    breakLine,
    getDomainFromReferrer,
    imageLoader,
    getPathFromUrl,
    clearHtml,
    getCnpjInfo,
    queryStringToObject,
    classThatStopped,
    loadRSS,
    formatAddress,
    generateKey,
    formatDate,
    objectToArray,
    showError,
    replaceDomain,
    customImageLoader,
    removeSlidesEmpty,
    firstName,
    useSetUTMParameters,
    useGetUTMParameters,
    clearCacheByTag,
    downDate,
    getAccid,
    calculateIncreasePercentage,
    getRecaptcha,
    isInAppBrowser,
    getUrlSite,
    capitalizeFirstLetter,
    validateQuerySlug,
    cloudflareLoader
};
