import styles from "./styles.module.scss";
import { Newsletter } from "./Newsletter";
import useView from "../Hooks/useView";
import { Accordions } from "./Accordions";
import { FooterMenuDesk } from "./FooterMenuDesk";
import { Sac } from "./Sac";
import { Bottom } from "./Bottom";
import { THEME_SETTING } from "@/setting/setting";

export const Footer = ({ account }: any) => 
{
  const { innerWidth = 0 } = useView();

  return (
    <footer className={styles.footer}>
        <div className={styles.content}>
          {!THEME_SETTING.disabledNewsletter && <Newsletter account={account} />}
          {innerWidth <= 780 ? <Accordions /> : <FooterMenuDesk />}
          <Sac account={account} />          
        </div>
        <Bottom />
    </footer>
  );
};
