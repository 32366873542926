import styles from "./styles.module.scss";
import { FaTiktok } from "react-icons/fa";
import { THEME_SETTING } from "../../setting/setting";
import { BsFacebook, BsInstagram, BsPinterest, BsYoutube } from "react-icons/bs";

export const Sac = ({ account }: any) => {
  return (
    <section className={styles.sac}>
      <div>
        <h1>
          SAC
        </h1>
        <div dangerouslySetInnerHTML={{ __html: THEME_SETTING.sacInfo1 }}></div>
      </div>

      <div className={styles.socials}>
          {account?.instagram && <BsInstagram
            onClick={() => window.open(account?.instagram, "_blank")}
          />}

          {account?.pinterest && <BsPinterest
            onClick={() => window.open(account?.pinterest, "_blank")}
          />}

          {account?.facebook && <BsFacebook
            onClick={() => window.open(account?.facebook, "_blank")}
          />}

          {account?.youtube && <BsYoutube
            onClick={() => window.open(account?.youtube, "_blank")}
          />}

          {account?.tiktok && <FaTiktok
            onClick={() => window.open(account?.tiktok, "_blank")}
          />}
        </div>
      {/* <div>
        <h1>
          <i>
            <img
              width={20}
              height={20}
              src="/assets/icons/whatsapp.svg"
              alt="WhatsApp"
            />
          </i>
          SAC
        </h1>
        <div dangerouslySetInnerHTML={{ __html: THEME_SETTING.sacInfo2 }}></div>
      </div> */}
    </section>
  );
};
