import styles from "./styles.module.scss";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { AiOutlinePlus } from "react-icons/ai";
import { BsBagFill, BsTrash } from "react-icons/bs";
import { CART_SETTING, THEME_SETTING } from "../../setting/setting";
import { currencyMask } from "../../core-nextv3/util/mask";
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { RiSubtractLine } from "react-icons/ri";
import {
    delGiftCart,
    delItemCart,
    setItemCart,
    validateCart,
} from "../../core-nextv3/cart/cart.api";
import { ImageSet } from "../ImageSet";
import { firstImageItemCart } from "../../core-nextv3/cart/cart.util";
import { useCore } from "../../core-nextv3/core/core";
import { AnimatedLoading } from "../AnimatedLoading";
import { useTranslation } from "react-i18next";

export const CartItem = ({ setModalBag, user }: any) => 
{
    const router                                                   = useRouter();
    const { t }                                                    = useTranslation()
    const { setCart, cart, awaitLoadingHTTP, setAwaitLoadingHTTP } = useCore();
    console.log("cart", cart)

    async function handleIncrementQuantity(item: any) 
    {
        const product  = item.product;
        const variant  = item.variant;
        const quantity = item.quantity;
        
        const newData: any = {
            data : {
                product : {
                    referencePath : product.referencePath,
                },
                quantity : quantity + 1,
            },
        };

        // PRODUCT VARIANT
        if (variant) 
        {
            newData.data.variant = [ ...variant ];
            product.id           = item.id;
        }

        tagManager4.addToCart(router.asPath, product, 1, user);

        const result = await setItemCart(CART_SETTING.merge(newData));

        if (result.status) 
        {
            toast.success("Quantidade alterada com sucesso!");
            setCart(result.data);
        }
        else 
        {
            return toast.error(result.error, {
                duration : 3000,
            });
        }
    }

    async function handleDecrementQuantity(item: any) 
    {
        const product  = item.product;
        const variant  = item.variant;
        const quantity = item.quantity;

        const newData: any = 
        {
            data : {
                product : {
                    referencePath : product.referencePath,
                },
                quantity : quantity - 1,
            },
        };

        // PRODUCT VARIANT
        if (variant) 
        {
            newData.data.variant = [ ...variant ];
            product.id           = item.id;
        }

        tagManager4.removeFromCart(router.asPath, product, 1, user);

        const result = await setItemCart(CART_SETTING.merge(newData));

        if (result.status) 
        {
            toast.success("Quantidade alterada com sucesso!");
            setCart(result.data);
        } 
        else 
        {
            return toast.error(result.error, {
                duration : 3000,
            });
        }
    }

    async function handleDeleteProduct(item: any) 
    {
        const product = item.product;
        const variant = item.variant;
        product.id    = item.id;

        tagManager4.removeFromCart(router.asPath, product, item.quantity, user);

        const newData: any = {
            data : {
                product : {
                    referencePath : product.referencePath,
                },
            },
        };

        // PRODUCT VARIANT
        if (variant) 
        {
            newData.data.variant = [ ...variant ];
        }

        let result;

        if (item.gift) 
        {
            result = await delGiftCart(CART_SETTING.merge(newData));
        } 
        else 
        {
            result = await delItemCart(CART_SETTING.merge(newData));
        }

        toast.success("Produto excluído com sucesso!");

        setCart(result.data);
    }

    async function handleValidateCart() 
    {
        setAwaitLoadingHTTP(true);

        const result = await validateCart(CART_SETTING);
        
        setCart(result.data);

        setAwaitLoadingHTTP(false);
    
        if (result.status) 
        {
            setModalBag(false);

            router.push("/checkout");
        }
        else 
        {
            let message = "";
      
            if (result.error instanceof Object)
            {
                for (const key in result.error)
                {
                    message = result.error[key];
                    break;
                }
            }
            else
            {
                message = result.error;
            }
      
            return toast.error(message,
                {
                    duration : 3000,
                });
        }
    }

    return (
        <div className={styles.cartItem}>
            {cart && cart?.items?.length > 0 ? (
                <>
                    {cart?.items?.map((item: any, index: any) => (
                        <div className={styles.content} key={index}> 
                            <div className={styles.product}>
                                <div className={styles.img} onClick={() => window.open(`/produto/${item?.product?.slug}?color=${item?.variant[0]?.value}&size=${item?.variant[1]?.value || ""}&imageType=${item?.product?.imageType?.value || ""}`)}>
                                    <ImageSet image={firstImageItemCart(item)}
                                        width={THEME_SETTING.widthProductThumb}
                                        height={THEME_SETTING.heightProductThumb}
                                        sizes="25vw"
                                    />
                                </div>
                                <div className={styles.descriptionProduct}>
                                    <p className={styles.title}>{item?.product?.name}</p>
                                    {/* <p className={styles.description}>Descrição</p> */}                        
                                    <div className={styles.productInfo}>
                                        <div className={styles.productDetails}>
                                            {item?.variant && item?.variant?.map((item2:any, index2:any) => 
                                                (
                                                    <p className={styles.productColor} key={index2}>
                                                        {item2?.type?.name}: {item2?.label}
                                                    </p>
                                                ))} 
                                        </div>
                                    </div>
                                    <div className={styles.prices}>
                                        <div className={styles.itemPrice}>
                                            {item?.promotionalPrice > 0 && <p className={styles.pricePromotional}>{currencyMask(item?.realPrice)}</p>}
                                            <p className={styles.price}>{currencyMask(item?.price)}</p>
                                        </div>
                                        <p className={styles.price}>{currencyMask(item?.total)}</p>
                                    </div>
                                    {!item?.gift && (
                                        <div className={styles.quantity}>
                                            <RiSubtractLine
                                                onClick={() => handleDecrementQuantity(item)}
                                            />
                                            <p className={styles.number}>{item?.quantity}</p>
                                            <AiOutlinePlus
                                                onClick={() => handleIncrementQuantity(item)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <BsTrash onClick={() => handleDeleteProduct(item)} />
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <p className={styles.noProducts}>
                    <BsBagFill></BsBagFill>
                    Seu carrinho está vazio. :(
                </p>
            )}
            {cart && cart?.items?.length > 0 ? (
                <div className={styles.fixed}>
                    <div className={styles.priceTotal}>
                        {cart?.totalDiscount > 0 && <div className={styles.separator}>
                            <p className={styles.priceName}>Desconto:</p>
                            <p className={styles.discount}>{currencyMask(cart?.totalDiscount)}</p>
                        </div>}

                        <div className={styles.separator}>
                            <p className={styles.priceName}>{t("SubTotal")}:</p>
                            <p className={styles.priceText}>{currencyMask(cart?.totalItems)}</p>
                        </div>

                        <div className={styles.dicount}>
                            <p>
                                {t("Desconto")}:
                                {cart?.discountPaymentMethod < 0 && (
                                    <span>({cart?.paymentMethod?.label})</span>
                                )}
                                {cart?.coupon && <span>({t("Cupom")})</span>}
                                {cart?.discountClient > 0 && <span>({t("Crédito")})</span>}
                            </p>
                            <p>{currencyMask(cart?.totalDiscount || 0)}</p>
                        </div>
                  
                        <div className={styles.separator}>
                            <p className={styles.priceName}>Total:</p>
                            <p className={styles.priceText}>
                                {currencyMask(cart?.total)}
                            </p>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <button
                            type="button"
                            onClick={() => 
                            {
                                handleValidateCart();
                            }}
                        >
                            {awaitLoadingHTTP ? <AnimatedLoading /> : "Finalizar Compra"}
                        </button>
                        {/* <button
                            className={styles.text}
                            onClick={() => 
                            {
                                router.push("/carrinho/"); setModalBag(false);
                            }}
                        >
                            Ir Para o Carrinho
                        </button> */}

                    </div>
                </div>
            ) : null }
        </div>
    );
};
