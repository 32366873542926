import "../styles/globals.scss";
import { AppPage } from "../elehome-next3/AppPage";

function MyApp({ Component, pageProps }: any) 
{  
    return(
        <AppPage Component={Component} pageProps={pageProps} />
    );
}

export default MyApp  