export const header_submenu_animation = {
  animate: {
    height: "0",
    overflow: "hidden",
    display: "flex",
    transition: {
      type: "tween",
      duration: 0.35,
      ease: "easeInOut",
    },
  },

  hover: {
    height: "auto",
    display: "flex",
    transition: {
      type: "tween",
      duration: 0.35,
      ease: "easeInOut",
    },
  },
};

export const headerAnimationMobile = {
  hidden: {
    opacity: 0,
    y: -30,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      type: "tween",
    },
  },
  exit: {
    opacity: 1,
    y: -350,
    transition: {
      duration: 0.2,
      type: "tween",
    },
  },
};

export const modalBagAnimation = {
  hidden: {
    opacity: 0,
    x: 10,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      type: "tween",
    },
  },
  exit: {
    opacity: 0,
    x: 10,
    transition: {
      duration: 0.5,
      type: "tween",
    }
  },
};


export const searchAnimation = {
  hidden: {
    opacity: 0,
    y: -250,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      type: "tween",
    },
  },
  exit: {
    opacity: 0,
    y: -250,
    transition: {
      duration: 0.5,
      type: "tween",
    }
  },
};