import styles from "./styles.module.scss";
import { useState } from "react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

export const AccordionSubMenuItem = ({ children, title, number }: any) => {
    const router = useRouter();
    const [toggle, setToggle] = useState(false)

    return (
        <motion.div
            layout
            className={styles.accordionSubMenuItem}
            onClick={() => setToggle(!toggle)}
        >
            <motion.p className={styles.accordionTitle} layout>
                {number ? <span>{number}</span> : null}

                {title}
                {toggle ? (
                    <IoIosArrowDown className={styles.accordionSvg} />
                ) : (
                    <IoIosArrowForward className={styles.accordionSvg} />
                )}
            </motion.p>
            {toggle ? children : ""}
            {/* <div className="accordion-line"></div> */}
        </motion.div>
    );
}